import React, { useState } from 'react'
import Header from './Header'
import Pickuplines from './Pickuplines'
import Footer from './Footer'
import { Button } from '@mui/material';
import MediaControlCard from './Card';


const Main = () => {

    const [pickuline, setPickupline] = useState([])
    const [status, setStatus] = useState(true)

    const pickulines = async () => {
        setStatus(false)
        const data = await fetch("https://api.openai.com/v1/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer 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",
            },
            body: JSON.stringify({
                prompt: `Generate a JavaScript object which is JSON.stringify for Random 5 Pick up line for girls.`,
                max_tokens: 3590,
                n: 1,
                temperature: 0.2,
                model: "text-davinci-002",
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(JSON.parse(data.choices[0].text))
                return JSON.parse(data.choices[0].text);
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                setStatus(true)
            })
        setPickupline(data)
    }

    const dbPickuplines = [
        {
            title: "Best Pickup Lines for Girls",
            para: `You're at a social event, and across the room, you spot someone who catches your eye. Your heart races,
        and you want to approach her, but you're not sure what to say. That's where pickup lines come in –
        those clever phrases that can turn an awkward encounter into a memorable connection. But how do you know which pickup line to use? Which ones are genuine icebreakers, and which ones might fall flat? In this article,
        we'll guide you through the art of pickup lines and give you a toolkit of the best ones to try.`,
            pickulines: [
                {
                    line: "You’ve got everything I’ve been searching for, and believe me—I’ve been looking a long time."
                },
                {
                    line: "Do you have a name, or can I just call you 'mine'",
                },
                {
                    line: "I never believed in love at first sight, but that's before I saw you."
                },
                {
                    line: `Do you have a map? Because I just got lost in your eyes.`
                },
                {
                    line: `You owe me a drink. Because when I saw you, I dropped mine.`
                },
                {
                    line: `They say nothing lasts forever, so will you be my nothing?`
                },
                {
                    line: `Want to go outside for some fresh air? You took my breath away.`
                },
                {
                    line: `If you were a song, you'd be the best single on the album.`
                },
                {
                    line: `You remind me of a magnet because you sure are attracting me over here.`
                },
                {
                    line: `I’m going to have to ask you to leave, you’re making everyone else in here look bad.`
                },
                {
                    line: `My parents told me not to talk to strangers, but I’ll make an exception for you.`
                }
            ]
        },
        {
            title: "Funny Pickup Lines for Girls",
            para: `
            Embracing the enigmatic charm of pick-up lines, despite their often quirky reputation, can indeed open doors to engaging 
            conversations and the acquisition of new vocabulary. Through the use of these lines, one can delve into the multiple nuances and meanings of words. 
            Consider the line "(You) pop, sparkle, and buzz electric" from Leonard. While these words might seem science-oriented, 
            they actually carry a deeper connotation. In this context, they transcend their literal definitions and instead depict someone as
             captivating, vibrant, and brimming with vitality.`,
            pickulines: [
                {
                    line: "Do you believe in love at first sight, or should I walk by again?"
                },
                {
                    line: "It's a good thing I have my library card, because I am totally checking you out.                    ",
                },
                {
                    line: "Your hand looks lonely. Can I hold it for you?"
                },
                {
                    line: `I believe in following my dreams. Can I have your Instagram?`
                },
                {
                    line: `Is your name Google? Because you're everything I've been searching for.`
                },
                {
                    line: `To quote the poet Katy Perry, "You make me feel like I'm living a teenage dream."`
                },
                {
                    line: `Let’s taco ‘bout how amazing you are over tacos and margaritas next week.`
                },
                {
                    line: `Do you play soccer? Because you look like a keeper.                    `
                },
                {
                    line: `How can I plan our wedding if I don’t have your number?`
                },
                {
                    line: `You can delete the dating app now, I’m here.`
                },
                {
                    line: `Are your parents bakers? Because you're a cutie pie.`
                }
            ]
        },
        {
            title: "Cheesy Pickup Lines",
            para: `If you are looking for Cheesy Pickup Lines, then we have collected some of the best attractive 
            Cheesy Pickup Lines for you, which you can use, all of them are excellent.
            It's essential to acknowledge that while these phrases may lack the sweetness of honey, they possess the ability to leave an indelible mark. 
            As you venture forth, armed with these linguistic treasures, exercise caution in their application, much like a Rosy-faced lovebird weaving 
            its tender affections. May your endeavors be met with triumph, guided by the arrows of Cupid himself! Best of luck!`,
            pickulines: [
                {
                    line: `If you were a fruit, you'd be a "fine-apple."`
                },
                {
                    line: `Aren't you tired? From running through my mind all day?`
                },
                {
                    line: `If I could rearrange the alphabet, I'd put U and I together.`
                },
                {
                    line: `Well, here I am! What are your other two wishes?`
                },
                {
                    line: `Are you a parking ticket? You've got fine written all over you.`
                },
                {
                    line: `If you were a chicken, you'd be "im-peck-able"`
                },
                {
                    line: `Did you just come out of an oven? Because you're too hot to handle.`
                }
            ]
        },
        {
            title: "Best Pickup Lines",
            para: `Pick-up lines are funny phrases you can use when you want to talk to someone you like and want to date or get to know better. 
            These lines are great for starting conversations in places like parties or bars, where you meet someone you find interesting. 
            When you use a silly pick-up line, it can help you break the ice and show that you're a fun person with a good sense of humor. 
            If you're looking to chat with someone you're interested in during 2023, here are some of the best funny pick-up lines you can use to 
            start a conversation and maybe even ask them out on a date!
            `,
            pickulines: [
                {
                    line: "Are you a magician? Because whenever I look at you, everyone else disappears!"
                },
                {
                    line: "Was your dad a boxer? Because damn, you’re a knockout!"
                },
                {
                    line: "I was wondering if you had an extra heart. Mine was just stolen."
                },
                {
                    line: "Would you grab my arm, so I can tell my friends I’ve been touched by an angel?"
                },
                {
                    line: "There’s only one thing I want to change about you, and that’s your last name."
                },
                {
                    line: "Aside from being sexy, what do you do for a living?"
                },
                {
                    line: "Kiss me if I’m wrong, but dinosaurs still exist, right?"
                },
                {
                    line: "Is your name Google? Because you have everything I’ve been searching for."
                }
            ]
        }
    ]

    return (
        <div className='root-conatiner'>
            <div className='pickupline-container'>
                <div className='pickup-header-section' style={{ backgroundColor: "rgb(254, 83, 135)" }}>
                    <Header />
                </div>
                <div className='pickup-middel-section'>
                    <div style={{ width: "100%", height: "auto", paddingTop: "2rem" }}>
                        <Button onClick={pickulines} className='btn-grad'>Generate Pick up lines</Button>
                        {
                            status ? <Pickuplines
                                pickuline={pickuline}
                            /> : <div>Lodding ....</div>
                        }
                    </div>
                    <div className='previous-results'>
                        {
                            dbPickuplines.map((item, index) => {
                                return <div className='pickuplines-for-gender'>
                                    <h2>{item.title}</h2>
                                    <p>
                                        {item?.para}
                                    </p>
                                    <ul>
                                        <li>
                                            <MediaControlCard
                                                line={item?.pickulines[0]?.line}
                                                height="auto"
                                            />
                                        </li>
                                        <li>
                                            <MediaControlCard
                                                line={item?.pickulines[1]?.line}
                                                height="auto"
                                            />
                                        </li>
                                        <li>
                                            <MediaControlCard
                                                line={item?.pickulines[2]?.line}
                                                height="auto"
                                            />
                                        </li>
                                        <li>
                                            <MediaControlCard
                                                line={item?.pickulines[3]?.line}
                                                height="auto"
                                            />
                                        </li>
                                        <li>
                                            <MediaControlCard
                                                line={item?.pickulines[4]?.line}
                                                height="auto"
                                            />
                                        </li>

                                    </ul>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className='pickup-footer-section'>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Main
import React from 'react'

const Footer = () => {
    return (
        <div className='footerContainer'>
            <div className='container'>
                <div className='fotter-content'>
                    <a href='/about-us' target='_blank'>About us</a>
                    <a href='/privacy-policy' target='_blank'>Privacy Policy</a>
                    <a href='/term-condition' target='_blank'>Term Condition</a>
                    <a href='/disclaimer' target='_blank'>Disclaimer</a>
                </div>
            </div>
        </div>
    )
}


export default Footer

import React from 'react'
import HeaderCom from "./InCom"

const Header = () => {
    return (
            <HeaderCom/>
    )
}


export default Header

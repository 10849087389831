import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


export default function MediaControlCard({ line, height }) {
    const [content, setContent] = useState(line);

    const handleCopyClick = () => {
        // Create a new textarea element to copy the content to the clipboard
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = content;

        // Append the textarea to the DOM
        document.body.appendChild(tempTextArea);

        // Select the content of the textarea
        tempTextArea.select();
        tempTextArea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the content to the clipboard
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(tempTextArea);

        alert('Content Copied!');
    };

    return (
        <Card sx={{ display: 'flex', height: height ? height : "200px", padding: height ? '0rem' : "1rem 0rem", backgroundColor: "#FE5387" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="text.secondary" component="div" style={{ color: "white" }}>
                        {content}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'flex-end', p: 1 }}>
                    <IconButton aria-label="previous" onClick={handleCopyClick}>
                        <i className="ri-file-copy-line" style={{color:"white"}}></i>
                    </IconButton>
                </Box>
            </Box>
        </Card>
    );
}
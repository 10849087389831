import React, { useState } from 'react'
import MediaControlCard from "./Card"

const Pickuplines = ({ pickuline }) => {
    return (
        <div style={{ width: "50%", margin: "auto", display: "flex", columnGap: "5rem", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
            {
                pickuline.pickUpLines?.map((lines, index) => {
                    return <div key={index} style={{margin: "1rem auto", width: "50%", flexBasis: "200px", flexGrow: 1 }}>
                        <MediaControlCard
                            line={lines}
                        />
                    </div>
                })
            }
        </div>
    )
}

export default Pickuplines